<template>
  <div class="home-service">
    <div class="service-tab">
      <div class="header">
        <div class="text">
          <img src="../assets/icon0.png" alt="" />7x24小时服务支持
        </div>
        <div class="text">
          <img src="../assets/icon1.png" alt="" />智能注册高性价比
        </div>
        <div class="text">
          <img src="../assets/icon2.png" alt="" />专利顾问 指导注册
        </div>
        <div class="text">
          <img src="../assets/icon3.png" alt="" />担保服务 不过包退
        </div>
        <div class="text">
          <img src="../assets/icon4.png" alt="" />强大品牌背书
        </div>
      </div>
      <div class="footer">
        <div>
          {{ $store.state.user.web_data.copy_right.value }}
        </div>
        <div>
          ICP备案号：<a
            target="_blank"
            :href="JSON.parse($store.state.user.web_data.web_info.value).url"
            class="text"
            >{{ JSON.parse($store.state.user.web_data.web_info.value).code }}</a
          >
          国家知识产权局备案代理机构
          <!-- <span class="text">法律声明 隐私服务条款</span> -->
          <a
            target="_blank"
            :href="$store.state.user.web_data.legal_declaration.value"
            class="text"
          >
            {{ $store.state.user.web_data.legal_declaration.name }}
          </a>
          <a
            target="_blank"
            :href="$store.state.user.web_data.privacy_terms.value"
            class="text"
          >
            {{ $store.state.user.web_data.privacy_terms.name }}
          </a>
        </div>
        <div>
          {{ $store.state.user.web_data.hot_line.value }}
        </div>
        <div>
          <span>{{ $store.state.user.web_data.service_time.name }}：</span>
          <span>{{ $store.state.user.web_data.service_time.value }}</span>
          <span>&nbsp;&nbsp;&nbsp;{{ $store.state.user.web_data.enter_address.name }}：</span>
          <span>{{ $store.state.user.web_data.enter_address.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  components: {},

  directives: {},

  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.home-service {
  height: 342px;
  background: #333333;

  .service-tab {
    width: 1200px;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;

      img {
        padding-left: 5px;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 1200px;
        height: 1px;
        background: #fff;
      }

      .text {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 158px;
        color: #ffffff;
      }
    }

    .footer {
      padding-top: 50px;
      color: #fff;
      text-align: center;
      line-height: 28px;
      font-size: 12px;

      .text {
        color: #2680eb;
        cursor: pointer;
      }
    }
  }
}
</style>