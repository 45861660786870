<template>
  <div class="copyright-tab">
    <div
      v-for="(item, index) in copyright"
      :key="index + 'copyright'"
      class="copyright-item"
    >
      <div class="right">
        <div class="header">
          <img :src="item.imgurl" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{ item.text }}
          </div>
        </div>
        <div class="footer">
          <div class="left">
            <span class="text">申请价格:</span>
            <span class="sign">￥</span>
            <span class="money">{{ item.money }}</span>
          </div>
          <div class="right" @click="showModel(item)">立即咨询</div>
        </div>
      </div>
    </div>
    <Model ref="model" />
  </div>
</template>

<script>
import Model from "../../comon/model.vue";
export default {
  name: "Copyrightregistration",
  components: { Model },
  data() {
    return {};
  },
  props: {
    copyright: Array,
    more: String,
    patentitle: Array,
    title: String,
    showtitle: Boolean,
    type: String
  },
  methods: {
    showModel(item) {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms = item;
    },
  },
};
</script>

<style lang="less" scoped>
.copyright-tab {
  width: 1200px;
  background: #fff;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

  .copyright-item {
    width: 400px;
    height: 240px;
    padding: 0px 52px 20px 75px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 40px;
      width: 1px;
      height: 160px;
      background: #e5e8ed;
    }

    .right {
      .header {
        position: relative;

        img {
          position: absolute;
          left: -40px;
          top: 5px;
        }

        .title {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          text-align: left;
        }

        .text {
          font-size: 14px;
          margin-top: 10px;
          text-align: left;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          height: 100px;
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }

          .sign {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f13a3b;
          }

          .money {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f13a3b;
          }
        }

        .right {
          width: 70px;
          height: 32px;
          border: 1px solid #f13a3b;
          color: #f13a3b;
          text-align: center;
          line-height: 32px;
          cursor: pointer;

          &:hover {
            color: #fff;
            background: #f13a3b;
          }
        }
      }
    }
  }
}
</style>