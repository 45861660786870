import { render, staticRenderFns } from "./CopyrightRegistration.vue?vue&type=template&id=dd8c2286&scoped=true&"
import script from "./CopyrightRegistration.vue?vue&type=script&lang=js&"
export * from "./CopyrightRegistration.vue?vue&type=script&lang=js&"
import style0 from "./CopyrightRegistration.vue?vue&type=style&index=0&id=dd8c2286&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd8c2286",
  null
  
)

export default component.exports