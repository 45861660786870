<template>
  <div class="innovative-service-info">
    <div class="fast-application">
      <div class="title">
        <div>创新企业服务，获得竞争优势</div>
      </div>
      <div class="intelligent">
        <div class="intelligent-title">{{ innovativeseList.patentTitle }}</div>
        <div class="application-Info">
          <div>
            <a-input
              v-model="innovativeseList.title"
              style="width: 320px"
              placeholder="请输入企业名称"
            />
          </div>
          <div style="margin-left: 20px">
            <a-input
              v-model="innovativeseList.tel"
              style="width: 320px"
              placeholder="手机号码"
            />
          </div>
          <div class="obtain-code" style="margin-left: 20px">
            <a-input
              v-model="innovativeseList.code"
              class="inp"
              style="width: 110px"
              placeholder="验证码"
            />
            <span class="text"
              ><span
                style="
                  border-radius: 10px;
                  cursor: pointer;
                  width: 100px;
                  text-align: center;
                "
                class="msg__btn"
                @click="getSmsCode"
                :disabled="state.smsSendBtn"
                >{{
                  state.smsSendBtn ? state.time + "后在获取" : "获取验证码"
                }}</span
              >
            </span>
          </div>
          <a-button @click="setNeeds()" style="margin-left: 20px" type="danger">
            提交需求
          </a-button>
        </div>
      </div>
    </div>
    <div class="fast-info">
      <div class="header">热门创新服务</div>
      <CopyrightRegistration
        :copyright="$store.state.home.innovationList.serviceList"
        :type="'innovationList'"
      />
    </div>
    <div class="patent-value-info">
      <div class="value-info">
        <div class="value-title">
          <div class="value-tab">
            <div
              class="value-item"
              v-for="(item, index) in $store.state.home.innovationList.keyan"
              :key="index + 'value'"
            >
              <div class="left">
                <div class="header">
                  <div class="title">{{ item.title }}</div>
                  <div class="text">
                    {{ item.text }}
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="price">
                  <span class="text">申请价格:</span>
                  <span class="sign">￥</span>
                  <span class="money">{{ item.money }}</span>
                </div>
                <div class="btn" @click="showModel(item)">立即咨询</div>
              </div>
            </div>
          </div>
        </div>
        <div class="title-tab">专项服务</div>
        <div class="value-tab">
          <div
            class="value-item"
            v-for="(item, index) in $store.state.home.innovationList
              .patentServiceList"
            :key="index + 'value'"
          >
            <div class="left">
              <div class="header">
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="price">
                <span class="text">申请价格:</span>
                <span class="sign">￥</span>
                <span class="money">{{ item.money }}</span>
              </div>
              <div class="btn" @click="showModel(item)">立即咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Model ref="model" />
    <Floating :consultant="$store.state.user.guwen.innovativeservice" />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../comon/Footer.vue";
import cookie from "../../utils/cookie";
import { needs, smsCode } from "../../api/index";
import Floating from "../../comon/Floating.vue";
import Model from "../../comon/model.vue";
import CopyrightRegistration from "../comon/CopyrightRegistration.vue";
export default {
  name: "CopyrightService",

  components: { CopyrightRegistration, Footer, Model, Floating },

  directives: {},

  data() {
    return {
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        selectindex: 0,
        smsSendBtn: false,
        read: false,
      },
      innovativeseList: {
        patentTitle: "创新服务",
        title: "",
        category: 4,
        tel: "",
        code: "",
      },
      patentlist: [
        {
          patentTitle: "发明专利申请",
          patentText:
            "保护对产品、方法或者其他所提出新的技术方 案，保护期限20年。",
          contentText1: "新产品、新材料、新物质等的技术方案",
          contentText2: "可以是一个独立、完整的产品",
          contentText3: "也可以是一个设备或仪器中的零部件",
          price: "",
          bg: "#E5EAF3",
          color: "#0A4B64",
        },
        {
          patentTitle: "实用新型专利申请",
          patentText:
            "保护对产品的形状、构造或者其结合所提出的技术方案，保护期10年。",
          contentText1: "实用新型只保护产品的形状、构造",
          contentText2: "实用新型没有实质审查程序",
          contentText3: "实用新型的费用低",
          price: "",
          bg: "#F4D2A4",
          color: "#7E502A",
        },
        {
          patentTitle: "外观专利申请",
          patentText:
            "保护对产品形状、图案或其结合以及色彩与形状、图案结合所做的新设计，保护期10年。",
          contentText1: "必须是对产品的外表所作的",
          contentText2: "设计 必须是适于工业上的应",
          contentText3: "用 必须富有美感",
          price: "",
          bg: "#221613",
          color: "#E4C8A9",
        },
      ],
      copyrightList: [
        {
          title: "单个国家直接申请",
          text: "一份申请，多国享有优先权。",
          money: "",
        },
        {
          title: "PCT国际申请",
          text: "单一国家申请专利是指中国申请人直接到国外的一个国家或一个地区申请专利。",
          money: "",
        },
      ],
      copyrightList1: [
        {
          title: "贯标申请服务",
          text: "规范企业知识产权管理。",
          money: "",
        },
        {
          title: "知识产权保护",
          text: "提供全面的知识产权保护方案。",
          money: "",
        },
        {
          title: "网络侵权处理",
          text: "对互联网中的知识产权侵权进行处理。",
          money: "",
        },
        {
          title: "专项风险预警",
          text: "围绕企业专项申请做全面的风险预警。",
          money: "",
        },
        {
          title: "专项侵权对抗",
          text: "专项对其他企业发生侵权时提供对抗方案。",
          money: "",
        },
        {
          title: "无效/异议提起",
          text: "对其他企业的专项提起无效、异议申请。",
          money: "",
        },
        {
          title: "专项侵权对抗",
          text: "专项对其他企业发生侵权时提供对抗方案。",
          money: "",
        },
      ],
      lawList1: [
        {
          title: "国家高新企业认定",
          text: "享受税收减免优惠，获得政府政策扶持，推动企业快速发展。",
          money: "",
        },
        {
          title: "双软认证",
          text: "通过软件产品登记和软件企业认定给予企业税收减免等政策支持。",
          money: "",
        },
        {
          title: "专利无效宣告",
          text: "企业项目，可申请政府企业扶持资金、贴息、免息贷款。",
          money: "",
        },
      ],
      lawList: [
        {
          title: "专利申请复审",
          text: "一份申请，多国享有优先权。",
          money: "",
        },
        {
          title: "专利权恢复",
          text: "专利权终止后2个月内，可以向国务院专利行政部门请求恢复权利。",
          money: "",
        },
        {
          title: "专利无效宣告",
          text: "允许对他人的专利不符合专利法规定条件的发明创造专利权提出无效宣告请求。",
          money: "",
        },
        {
          title: "专利无效答辩",
          text: "专利遭遇他人提出无效宣告请求时需要进行。",
          money: "",
        },
        {
          title: "审查意见答复",
          text: "对国知局下发的审查意见通知书进行规范答复。",
          money: "",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    async getSmsCode(e) {
      if (this.state.smsSendBtn)
        return this.$message.error("请勿重复提交验证码");
      if (this.innovativeseList.tel === "")
        return this.$message.error("请填写手机号");
      e.preventDefault();
      const {
        form: { validateFields },
        state,
      } = this;
      const params = {
        phone: this.innovativeseList.tel,
        useTo: 6,
      };
      const res = await smsCode(params);
      validateFields(["subForm[phone]"], { force: true }, (err) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          // tianli
          if (res.code === 200) {
            setTimeout(() => {
              this.$message.success({
                content: "发送成功，请在手机中查看!",
                duration: 2,
              });
            }, 800);
          } else {
            setTimeout(() => {
              this.$message.success({
                content: "发送失败，请重新发送!",
                duration: 2,
              });
            }, 0);
          }
          setTimeout(hide, 2500);
        }
      });
    },
    async setNeeds() {
      if (this.innovativeseList.title === "")
        return this.$message.error("请填写企业名称");
      if (this.innovativeseList.tel === "")
        return this.$message.error("请填写手机号");
      if (this.innovativeseList.code === "")
        return this.$message.error("请填写验证码");
      const params = {
        ownerId: cookie.getCookie("user")
          ? JSON.parse(cookie.getCookie("user")).id
          : null,
        code: this.innovativeseList.code,
        title: this.innovativeseList.title,
        category: this.innovativeseList.category,
        tel: this.innovativeseList.tel,
      };
      const res = await needs(params);
      if (res.code === 200) {
        this.$message.success("提交需求成功！");
        this.innovativeseList.contact = "";
        this.innovativeseList.tel = "";
        this.innovativeseList.code = "";
        this.innovativeseList.title = "";
      } else {
        this.$message.error(res.msg);
      }
    },
    showModel(item) {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms = item;
    },
  },
};
</script>

<style lang="less">
@import "../../less/innovativeservice.less";
</style>
